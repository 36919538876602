var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('create', 'companies'))?_c('companies-create-modal',{ref:"sidebar",attrs:{"id":"create-companies-modal"},on:{"addNewCompanyToList":function (company) { return _vm.rows.unshift(company); }}}):_vm._e(),_c('b-card',[_c('datatable-header',{attrs:{"title":_vm.$tc('modules.companies.description', 2),"genericSearchEnabled":true,"searchTermPlaceHolder":_vm.$t('common.search')},on:{"filterUpdated":_vm.onFilterUpdate},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"ml-2",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$root.$emit(
                            'bv::toggle::modal',
                            'create-companies-modal'
                        )}}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.addNew", { resource: _vm.$tc( "modules.companies.description", 1 ).toLowerCase(), }).toString())+" ")])]},proxy:true},{key:"advancedSearch",fn:function(ref){
                        var expanded = ref.expanded;
                        var onFilterChange = ref.onFilterChange;
return [_c('DatatableFilter',{attrs:{"availableDatatableFilters":_vm.availableDatatableFilters,"expanded":expanded},on:{"filterUpdated":_vm.onFilterUpdate,"onFilterChange":onFilterChange}})]}}])}),_c('datatable',{attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"paginationOptions":{
                enabled: true,
                mode: 'pages',
                perPage: _vm.dataTableOptions.pagination.perPage,
            },"rows":_vm.rows,"rtl":_vm.direction,"searchOptions":{
                enabled: true,
                externalQuery: _vm.searchTerm,
            },"totalRows":_vm.dataTableOptions.pagination.totalRows,"mode":"remote","theme":"my-theme"},on:{"on-row-click":_vm.onRowClick,"on-page-change":_vm.handleChangePage,"onPageSizeChange":_vm.handlePageSizeChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'relationships')?_c('span',_vm._l((props.row.relations),function(relation){return _c('span',{key:relation.id},[_c('b-badge',[_vm._v(" "+_vm._s(relation.description)+" ")])],1)}),0):(props.column.field === 'classifications')?_c('span',_vm._l((props.row.classifications),function(classification){return _c('span',{key:classification.id},[_vm._v(" "+_vm._s(classification.description)+" ")])}),0):(props.column.field === 'emails')?_c('span',_vm._l((props.row.emails),function(email){return _c('span',{key:email.id},[_vm._v(" "+_vm._s(email.address)+" ")])}),0):(props.column.field === 'phones')?_c('span',_vm._l((props.row.phones),function(phone){return _c('span',{key:phone.id},[_vm._v(" "+_vm._s(phone.number)+" ")])}),0):(props.column.field === 'status')?_c('span',[(props.row.status)?_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status.description)}},[_vm._v(" "+_vm._s(props.row.status.description)+" ")]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }